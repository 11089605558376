import React, { Component } from 'react'
import {connect} from 'react-redux'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import DateFromDateTo from './DateFromDateTo'
import dateFormat from "dateformat"
import { cardStatement, getSettlementChargebacks, clearSettlementChargebacks } from '../../../action/cardDetails'
import TransactionTable from './TransactionTable'
import { withTheme } from '@material-ui/core/styles'
import download from 'downloadjs'
import b64toBlob from '../../../util/b64toBlob'
import ReverseTransactionForm from './ReverseTransactionForm'
import { resetReverseTransaction } from '../../../action/transaction'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import SettlementChargebacksResults from "./SettlementChargebacksResults"

export const today = new Date()
export const threeMonthAgo =new Date( today.getFullYear(),(today.getMonth() -3), today.getDate())

class CardTransactions extends Component{

  constructor(props){
    super(props)
    this.state = {
      startDate: dateFormat(new Date(threeMonthAgo), "yyyy-mm-dd"),
      endDate: dateFormat(new Date(today), "yyyy-mm-dd"),
      reverseDialogOpen: false,
      transactionToReverse: null,
      isValidDate: true
    }
  }

  componentWillReceiveProps(nextProps){
    this.state.isValidDate && nextProps.cardDetailsResponse !== this.props.cardDetailsResponse&&
      this.props.cardStatement(this.props.token, nextProps.cardID, this.state.startDate, this.state.endDate)
  }

  handleInputChange = (event) => {
    const stateKey = event.target.name
    this.setState({ [stateKey]: event.target.value }, this.validateStatementDate)
  }

  validateStatementDate() {
    const currentStartDate = this.state?.startDate;
    const currentEndDate = this.state?.endDate;
    let parsedStartYear = parseInt(new Date(currentStartDate).getFullYear())
    let parsedEndYear = parseInt(new Date(currentEndDate).getFullYear())
    let parsedStartYearLength = parsedStartYear.toString().length;
    let parsedEndYearLength = parsedEndYear.toString().length;
    if(parsedStartYearLength === 4 && parsedEndYearLength === 4){
      this.setState({isValidDate: true});
      this.props.refresh()
    } else {
      this.setState({isValidDate: false});
    }
  }

  getFileName = (extension) => {
    return `CardStatementTrackingNumber_${this.props.cardDetails.TRACKINGNUMBER}_from${this.state.startDate}_to${this.state.endDate}.${extension}`
  }

  downloadExcelData = () => {
    const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const binaryExcelData = b64toBlob(this.props.excelBase64Data)
    download(binaryExcelData, this.getFileName('xlsx'), mimeType)
  }

  createCsvString = () => {
    let csvString = ""
    if (this.props.transactions.length > 0) {
      const headers = this.props.exportColumns.map( item => item.LABEL)
      csvString += headers.join(",") + "\r\n"

      this.props.transactions.forEach( transaction => {
        const lineElements = this.props.exportColumns.map( column =>  transaction[column.VALUE].toString().split(',').join(''))
        csvString += lineElements.join(",") + "\r\n"
      })
      csvString = "data:application/csv," + encodeURIComponent(csvString)
      return csvString
    }
  }

  handleReverseRequest = (transaction) => {
    this.setState({
      reverseDialogOpen: true,
      transactionToReverse: transaction
    })
  }

  showChargebackInfo = (authorisationId) => {
    this.props.getSettlementChargebacks(authorisationId)
  }

  handleCloseChargebacks = () => {
    this.props.clearSettlementChargebacks()
  }

  handleReverseClose = (refresh) => {
    this.setState({
      reverseDialogOpen: false,
      transactionToReverse: null
    })
    this.props.resetReverseTransaction()

    if (refresh){
      this.props.refresh()
    }
  }

  render(){
    return(
    <Card style={{margin: '2px', marginTop: '4px', padding: '40px'}}>
      <CardHeader title="Transaction Statement" action={this.props.failedTransaction}/>
      <CardContent>
        <Grid
          justify="space-between"
          container
        >
          <Grid item>
            <Typography gutterBottom variant="h5">
              <DateFromDateTo
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChangeStartDate={this.handleInputChange}
                onChangeEndDate={this.handleInputChange}
              />
            </Typography>
            { !this.state.isValidDate && <span style={{color:'red', font:'10px !important'}}>Please provide valid date with yyyy-mm-dd format</span> }
          </Grid>
          <Grid item>
            {
              ( this.props.cardDetails &&  this.props.transactions.length > 0 ) &&
               <a href={this.createCsvString()} download={this.getFileName('csv')}>
                <Tooltip title="CSV">
                  <Icon style={{verticalAlign: 'middle', cursor: 'pointer'}} color="primary">cloud_download</Icon>
                </Tooltip>
              </a>
            }
            {
              ( this.props.cardDetails &&  this.props.transactions.length > 0 ) &&
              <IconButton style={{verticalAlign: 'middle', cursor: 'pointer'}} onClick={this.downloadExcelData}>
                <Tooltip title="Excel"><Icon color="primary">receipt</Icon></Tooltip>
              </IconButton>
            }
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <TransactionTable transactions={this.props.transactions} columns={this.props.visibleColumns} reverse={this.handleReverseRequest} showChargebackInfo={this.showChargebackInfo} />
      </CardContent>
      <ReverseTransactionForm open={this.state.reverseDialogOpen} close={this.handleReverseClose} cardNumber={this.props.cardDetails.CARDNUMBER} transaction={this.state.transactionToReverse} />

      <SettlementChargebacksResults isOpen={this.props.chargebacks && this.props.chargebacks.length > 0}
                                    close={this.handleCloseChargebacks} chargebacks={this.props.chargebacks}/>

    </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    cardDetailsResponse: state.cardDetails.cardDetailsResponse,
    transactions: state.cardDetails.transactions,
    visibleColumns: state.cardDetails.visibleColumns,
    exportColumns: state.cardDetails.exportColumns,
    columns: state.cardDetails.columns,
    excelBase64Data: state.cardDetails.excelBase64Data,
    chargebacks: state.cardDetails.chargebackInfo
  }
}

const mapDispatchToProps = {
  cardStatement, resetReverseTransaction, getSettlementChargebacks, clearSettlementChargebacks
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(CardTransactions))
